import React from 'react';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import styles from './Cookie.module.sass'
import { getCookie, setCookie } from 'Functions';
import { useState } from 'react';
import { contentActions } from 'actions'
import Checkbox from 'components/Checkbox';

/*
essential: false,
stats: false,
externalMedia: false
*/

const CookieTypes = [
    {
        title: 'Essenziell',
        cookieName: 'essential',
        allowCustomSetting: false,
        description: 'Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.',
        cookies: [
            {
                data: [
                    {
                        label: 'Name',
                        value: 'Lilianna Wysocki Cookie'
                    },
                    {
                        label: 'Anbieter',
                        value: 'Eigentümer dieser Website, <a href="impressum.html">Impressum</a>',
                    },
                    {
                        label: 'Zweck',
                        value: 'Speichert die Einstellungen der Besucher.',
                    },
                    {
                        label: 'Cookie Name',
                        value: '_settings',
                    },
                    {
                        label: 'Cookie Laufzeit',
                        value: '1 Jahr',
                    }
                ]
            }
        ]
    },
    {
        title: 'Externe Medien',
        cookieName: 'externalMedia',
        allowCustomSetting: true,
        description: 'Inhalte von Videoplattformen und Social-Media-Plattformen werden standardmäßig blockiert. Wenn Cookies von externen Medien akzeptiert werden, bedarf der Zugriff auf diese Inhalte keiner manuellen Einwilligung mehr.',
        cookies: [
            {
                data: [
                    {
                        label: 'Name',
                        value: 'YouTube',
                    },
                    {
                        label: 'Anbieter',
                        value: 'Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland',
                    },
                    {
                        label: 'Zweck',
                        value: 'Wird verwendet, um YouTube-Inhalte zu entsperren.',
                    },
                    {
                        label: 'Datenschutzerklärung',
                        value: '<a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>',
                    },
                    {
                        label: 'Host(s)',
                        value: 'google.com',
                    },
                    {
                        label: 'Cookie Name',
                        value: 'NID',
                    },
                    {
                        label: 'Cookie Laufzeit',
                        value: '6 Monate',
                    },
                ]
            },  

            {
                data: [
                    {
                        label: 'Name',
                        value: 'Google Maps',
                    },
                    {
                        label: 'Anbieter',
                        value: 'Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland',
                    },
                    {
                        label: 'Zweck',
                        value: 'Wird zum Entsperren von Google Maps-Inhalten verwendet.',
                    },
                    {
                        label: 'Datenschutzerklärung',
                        value: '<a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>',
                    },
                    {
                        label: 'Host(s)',
                        value: 'google.com',
                    },
                    {
                        label: 'Cookie Name',
                        value: 'NID',
                    },
                    {
                        label: 'Cookie Laufzeit',
                        value: '6 Monate',
                    },
                ]
            }   
        ]
    }
]


const CookieTypeSetting = ({ data, currentCookies, setCookie }) => {
    const [infoIsOpen, setInfoIsOpen] = useState(false)

    const cookieIsActive = currentCookies[data.cookieName]

    return (
        <div className="type">
            <span className="title">{data.title}</span>
            <p>{data.description}</p>
            {data.allowCustomSetting &&
                <button
                    className={`switch ${cookieIsActive ? 'active' : ''}`}
                    onClick={() => setCookie(data.cookieName, !cookieIsActive)}
                >
                    <span>{cookieIsActive ? 'An' : 'Aus'}</span>
                </button>
            }
            {(data.cookies.length>0) &&
                <>
                     <span
                        className="action"
                        onClick={() => setInfoIsOpen(!infoIsOpen)}
                    >{infoIsOpen ? 'Cookieinformationen ausblenden' : 'Cookieinformationen anzeigen'}
                    </span>
                    
                    {infoIsOpen &&
                        <div>
                            {data.cookies.map((cookie, index) => {
                                return (
                                    <div key={index} className="cookie-type">
                                        {cookie.data.map((row, index) => (
                                            <div key={index}>
                                                <div>{row.label}</div>
                                                <div dangerouslySetInnerHTML={{ __html: row.value }} />
                                            </div>
                                        ))}
                                    </div>
                                )
                            })}
                        </div>
                    }
                </>
            }
        </div>
    )
}

const CookieOverlay = ({ language, cookies, setCookies }) => {
    const [showOverlay, setShowOverlay] = useState(!cookies.essential)

    const [view, setView] = useState('overview')

    const [currentCookies, setCurrentCookies] = useState({ ...cookies })

    const onSaveAll = () => {

        const cookieNew = {
            essential: true,
            stats: true,
            marketing: true,
            externalMedia: true
        }

        setCookie(
            '_settings',
            JSON.stringify(cookieNew),
            60 * 24 * 365
        )

        setCookies(cookieNew)

        setCurrentCookies(cookieNew)
        setShowOverlay(false)
    }

    const onSave = () => {

        const cookieNew = {
            ...currentCookies,
            essential: true
        }

        setCookie(
            '_settings',
            JSON.stringify(cookieNew),
            60 * 24 * 365
        )

        setCookies(cookieNew)

        setCurrentCookies(cookieNew)
        setShowOverlay(false)
    }

    const onSetCookie = (name, value) => {

        console.log(name, value)

        setCurrentCookies({
            ...currentCookies,
            [name]: value
        })
    }

    console.log(currentCookies)

    if (!showOverlay) return null

    return (
        <div className={`${styles.root} ${view}`}>
            <div>
                <div className="center main">
                    <h6>Datenschutzeinstellungen</h6>
                    <div className="intro">
                        <div>
                            <div className="rich">
                            <p>Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern.</p>
                            <p>Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben möchten, müssen Sie Ihre Erziehungsberechtigten um Erlaubnis bitten.</p>
                            <p>Wir verwenden Cookies und andere Technologien auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für personalisierte Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung. Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer <a href="/datenschutz.html">Datenschutzerklärung</a>. Sie können Ihre Auswahl jederzeit unter <span className="action" onClick={() => setView('settings')}>Einstellungen</span> widerrufen oder anpassen.</p>
                            {(view === 'overview') &&
                                <div className="types">
                                    <Checkbox
                                        selected={true}
                                        onSelect={() => {}}
                                    >Essenziell</Checkbox>          
                                    <Checkbox
                                        selected={currentCookies.externalMedia}
                                        onSelect={(value) => {onSetCookie('externalMedia', value)}}
                                    >Externe Medien</Checkbox>
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <button className="save-all" onClick={onSaveAll}>Alle akzeptieren</button>
                        <button className="save" onClick={onSave}>Speichern</button>
                        {(view === 'settings') &&
                            <span className="action back" onClick={() => setView('overview')}>zurück</span>
                        }
                        {(view !== 'settings') &&
                            <div className="links">
                                <span className="action" onClick={() => setView('settings')}>Datenschutzeinstellungen</span>
                                <a href="/datenschutz.html">Datenschutzerklärung</a>
                                <a href="/impressum.html">Impressum</a>
                            </div>
                        }
                    </div>
                </div>
                {(view === 'settings') &&
                    <>
                        <div className="settings">
                            {CookieTypes.map((CookieType, index) => (
                                <CookieTypeSetting
                                    data={CookieType}
                                    currentCookies={currentCookies}
                                    setCookie={(name, value) => onSetCookie(name, value)}
                                    key={index}
                                />
                            ))}
                        </div>
                        <div className="links">
                            <a href="/datenschutz.html">Datenschutzerklärung</a>
                            <a href="/impressum.html">Impressum</a>
                        </div>
                    </>
                }
            </div>
        </div>
        </div >
    );
};

const mapStateToProps = (state, props) => {
    const { language, cookies } = state.content

    return {
        language,
        cookies
    }
}

const mapDispatchToProps = dispatch => ({
    setCookies: cookies => dispatch(contentActions.setCookies(cookies)),
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CookieOverlay))