import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { contentActions } from 'actions'

import { withRouter} from 'react-router-dom'

import CookieOverlay from './components/CookieOverlay'

function App({ location, getConfig, resize, language, setLanguage, cookies }) {

    const resizeTimeout = useRef(null)

    const test = () => {
        clearTimeout(resizeTimeout.current);
        resizeTimeout.current = setTimeout(resize, 100);
    }

    useEffect(() => {

        getConfig();

        window.addEventListener('resize', test)

        return () => {
            window.removeEventListener('resize', test)
        }
    }, [])

    useEffect(() => {

        let languageNew = location.pathname.split('/')[1] === 'en' ? 'en' : 'de'

        //console.log(languageNew, language)

        if (languageNew !== language) {
            setLanguage(languageNew)
        }


    }, [location])

    return (
        <>
            <CookieOverlay />
        </>
    )


}

const mapStateToProps = (state, props) => {
    const { language, cookies } = state.content

    return {
        language,
        cookies
    }
}

const mapDispatchToProps = dispatch => ({
    getConfig: () => dispatch(contentActions.getConfig()),
    resize: () => dispatch(contentActions.resize()),
    setLanguage: language => dispatch(contentActions.setLanguage(language)),
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App))
